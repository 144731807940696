<template>
  <v-container
    class="pa-0 px-0 py-0 white lighten-4"
    style="max-width: 600px; margin: 0 auto; height: 100%"
  >
    <div v-if="!loaded" class="px-3 mt-3">
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="loaded">
      <v-card elevation="0" class="pb-10 pt-2 px-2">
        <v-card-title style="font-size: 25px" class="pb-5">{{ feature.tag_name }}</v-card-title>
        <v-card-subtitle class="text-caption pa-0 pl-4">
          {{ feature.tag_description }}
        </v-card-subtitle>
      </v-card>

      <div ref="backToTopPoint"></div>

      <div class="back-to-top">
        <v-btn color="orange darken-2" v-show="showBackToTop" fab dark @click="backToTop()">
          <div class="back-to-top-btn">
            <v-icon x-large>mdi-chevron-up</v-icon>
            <span class="text-overline" style="line-height: 1.2">top</span>
          </div>
        </v-btn>
      </div>

      <div style="background-color: white">
        <v-row class="px-5 mb-2 py-2">
          <v-col
            style="position: relative"
            cols="6"
            v-for="product in showProducts"
            :key="product._id"
            class="px-1"
          >
            <AddToCart :product_id="product" />
            <v-card :id="product._id" @click="clicked(product._id)" elevation="0">
              <v-img
                class="white--text align-end ma-auto"
                :src="product.thumbnail"
                aspect-ratio="1"
                style="object-fit: cover; border-radius: 10px"
                gradient="0deg, rgba(0, 0, 0, 0.5) 15%, rgba(0, 0, 0, 0.11) 45%, rgba(0, 0, 0, 0) 86%"
              >
                <div
                  class="text-caption ml-1 mb-0 font-weight-bold line-clamp"
                  v-html="product.subtitle"
                ></div>
              </v-img>
              <v-card-text class="text-caption pa-0 pl-2 pt-1">
                <div class="text-truncate">
                  <span>{{ product.brand }}</span>
                </div>

                <div class="black--text font-weight-bold text-body-3 line-clamp">
                  {{ product.title }}
                </div>
                <div>
                  <v-chip v-if="product.min_sku.choice_name" class="mr-2" x-small label>
                    {{ product.min_sku.choice_name }}
                  </v-chip>
                </div>
                <div class="mx-1 my-1 black--text">
                  <div v-if="product.is_onsale" class="pt-2 text-body-1 deep-orange--text">
                    <div class="font-weight-bold">
                      <span class="text-decoration-line-through">{{ product.price }}</span>
                      <span>{{ ` ${Math.floor(product.sale_price || 0)} ` }}</span>
                      円
                    </div>
                    <div class="black--text text-caption" style="margin-top: -5px">
                      税込
                      <span class="text-decoration-line-through">
                        {{ Math.floor(product.price * (1 + product.tax)) }}
                      </span>
                      <span>{{ Math.floor(product.sale_price * (1 + product.tax)) }}</span>
                      円
                    </div>
                  </div>
                  <div v-else class="pt-2 text-body-1 deep-orange--text">
                    <div class="font-weight-bold">{{ product.price }}円</div>
                    <div class="black--text text-caption" style="margin-top: -5px">
                      税込{{ Math.floor(product.price * (1 + product.tax)) }}円
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="pay_dialog" persistent max-width="290">
      <v-card class="pb-6">
        <div class="text-end pr-3 py-2">
          <v-icon @click="close_dialog()">mdi-close</v-icon>
        </div>

        <v-card-text class="pb-1 text-center black--text font-weight-bold">
          ご注文ありがとうございます。
        </v-card-text>

        <v-card-text class="pb-1 text-center mx-1" v-if="!is_corporation">
          ご購入の手続きが完了されますと、LINE公式からご注文の受付確認メッセージが届きます。⸜( •⌄•)⸝
        </v-card-text>
        <v-card-text class="pb-1 text-center mx-1" v-if="is_corporation">
          ご購入の手続きが完了されますと、見積書は通常12時間以内にご担当者様のメールアドレスに送信されます。⸜(•⌄•
          )⸝
        </v-card-text>
      </v-card>
    </v-dialog>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import AddToCart from '@/components/AddToCart.vue';

  export default {
    name: 'FeaturesProducts',
    metaInfo: {
      title: 'レコセレ公式サイト',
      meta: [
        {
          name: 'description-',
          content: 'より良い生活を、より手頃に',
        },
      ],
    },
    data: () => ({
      loaded: false,
      tag_id: null,
      feature: [],
      products: [],
      pay_dialog: false,
      is_corporation: false,
      offsetTop: 0,
      brand: null,
      sku_model: [], //this the selected chain
      features: [],
      showBackToTop: false,
    }),
    components: {
      // QAComponent,
      AddToCart,
      BottomNavigation,
    },
    async created() {
      this.$store.commit('brand/set_sku_model', { sku_model: [] });
      this.tag_id = this.$route.params.tag_id;
      if (this.$store.state.brand.is_corporation) {
        this.is_corporation = true;
      }
      const { data } = await Methods.getFeatureTags(this.$route.params.tag_id);
      const feature = data;
      this.feature = feature;
      let features;
      if (feature && feature.tag_products) {
        features = feature.tag_products;
      } else {
        alert('特集データがエラー発生');
        this.$router.push('/features/');
      }
      let response = await Methods.getProducts({ is_archived: 0,is_soldout:0 });

      // let tree = new Tree(information.new_categories, 1);

      // if (this.sku_model.length < 2) {
      //   this.sku_model = [...tree.getOneChain()].reverse();
      // }
      // this.sku_tree = tree;

      for (let product of response.data.products) {
        /* set price to cheapest sku */
        let price = 100000;
        let min_sku = {};
        for (let sku of product.sku_objects) {
          if (sku.is_leaf == true) {
            if (sku.sku_product.price < price) {
              price = sku.sku_product.price;
              min_sku = sku;
            }
          }
        }
        product.price = price;
        if (product.is_onsale && product.sale_discount) {
          product.sale_price = price * product.sale_discount;
        }
        product.min_sku = min_sku;
        /*  filter archived products*/
        if (!product.is_archived) {
          this.products.push(product);
        }
      }
      features = features.filter((id) => {
        return response.data.products.find((product) => id === product._id);
      });
      this.features = features.map((id) =>
        response.data.products.find((product) => id === product._id),
      );

      this.loaded = true;

      if ('paid' in this.$route.query) {
        if (this.$route.query.paid == 'true') {
          this.pay_dialog = true;
          this.$store.commit('cart/setMyCart', []);
        }
      }
    },
    mounted() {
      if (this.$store.state.brand.sku_model.length > 1) {
        this.sku_model = this.$store.state.brand.sku_model;
      }
      window.addEventListener('scroll', this.handleScroll, true);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
      showProducts: function () {
        return this.features;
      },
    },
    methods: {
      close_dialog: function () {
        this.pay_dialog = false;
        this.$router.push('/');
      },
      title_index_TO_key: function (index) {
        return this.categories.indexOf(this.tab_titles[index]) + 1;
      },
      back_to_home: function () {
        this.$router.push('/');
      },
      clicked: function (id) {
        this.$router.push(`/features/${this.tag_id}/${id}`);
      },
      backToTop: function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      handleScroll: function () {
        //滚动条到底部的条件
        if (!this.$refs.backToTopPoint) return;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.$store.commit('brand/set_home_scrollTop', { scrollTop });
        if (this.$refs.backToTopPoint.getBoundingClientRect().y < -800) {
          this.showBackToTop = true;
        } else {
          this.showBackToTop = false;
        }
      },
      is_chip_color(depth) {
        if (depth == 1) {
          return 'orange';
        } else {
          return '';
        }
      },
      is_chip_small(depth) {
        if (depth == 1) {
          return false;
        } else {
          return true;
        }
      },
      is_chip_label(depth) {
        if (depth != 2) {
          return false;
        } else {
          return true;
        }
      },
      show_divider(depth) {
        if (depth == 1) {
          return false;
        } else {
          return true;
        }
      },
    },
    beforeRouteLeave(to, from, next) {
      this.$route.meta.scrollHeight = document.documentElement.scrollTop;
      next();
    },
  };
</script>

<style scoped>
  .back-to-top {
    position: fixed;
    left: 20px;
    bottom: 70px;
    z-index: 1000;
  }
  .back-to-top-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .v-window {
    height: calc(100% - 48px);
    /* タブ領域の高さを引く */
  }

  .v-tab__items,
  .v-window-item,
  .v-window >>> div.v-window__container {
    /* ここが重要 */
    height: 100%;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .left-border-red {
    background-color: #ffffff !important;
    border-left: 7px solid red !important;
  }
  .left-border-orange {
    background-color: #ffffff !important;
    border-left: 7px solid orange !important;
  }
  .left-border-yellow {
    background-color: #ffffff !important;
    border-left: 7px solid #ffd214 !important;
  }
</style>
